import { Alert, Stack, theme } from '@prophecy/ui';
import { tokens as alertTokens } from '@prophecy/ui/Alert/tokens';
import { getDuration } from '@prophecy/utils/date';
import { useTimedFlag } from '@prophecy/utils/react/hooks';
import { pluralize } from '@prophecy/utils/string';
import { uniq, uniqBy } from 'lodash-es';
import React, { useEffect, useMemo, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { QueryKeys } from '../../common/queries/common';
import { Private_Routes, Public_Routes } from '../../common/url';
import { UserTypes } from '../../common/UserTypes';
import { useAppMetadata } from '../../context/appMetadata';
import { getMonitoringAlertInfo } from '../../data/apis/api';
import { useRestQuery } from '../../data/util';
import { MetricType, MonitoringAlert, ServiceDetails } from './types';

const StyledAlert = styled(Alert)`
  a {
    text-decoration: underline;
    ${(props) => props.variant === 'error' && `color: ${alertTokens.Alert.error.color};`}
    ${(props) => props.variant === 'warning' && `color: ${alertTokens.Alert.warning.color};`}
    text-underline-offset: ${theme.spaces.x2};
    font-size: ${alertTokens.Alert.fontSize};
  }
`;

function toMetricName(metricType: MetricType) {
  switch (metricType) {
    case 'CPU_USAGE':
      return 'CPU';
    case 'MEMORY_USAGE':
      return 'Memory';
    case 'DISK_USAGE':
      return 'Disk';
    case 'FILE_COUNT':
      return 'File Storage';
  }
}

export function MonitoringNotification() {
  const isAdminUser = useAppMetadata()?.user?.type === UserTypes.ClusterAdmin;
  const isLoggedIn = Boolean(useAppMetadata()?.user?.id);
  const [showNotice, toggleNotice] = useState<boolean>(true);
  // refetch every 5 minutes
  const { data } = useRestQuery<MonitoringAlert>([QueryKeys.MonitoringAlert], getMonitoringAlertInfo, {
    refetchInterval: 5 * 60 * 1000
  });
  const criticalServices = useMemo(() => data?.data.critical || [], [data]);
  // show warning only for disk usage
  const warningServices = useMemo(
    () =>
      data?.data.warning?.filter(
        (service) => service.metricsType === 'DISK_USAGE' || service.metricsType === 'FILE_COUNT'
      ) || [],
    [data]
  );
  const getServiceNames = (details: ServiceDetails[]) => {
    const serviceNames = details.map((service) =>
      service.serviceName.startsWith('sandbox:') ? 'sandbox' : service.serviceName
    );
    const resourceNames = details.map((service) => toMetricName(service.metricsType));

    return {
      serviceNames: uniq(serviceNames).sort().join(', '),
      resourceNames
    };
  };
  const location = useLocation();
  const isMonitoringPage =
    location.pathname.includes(Private_Routes.Settings.tab.getUrl({ tab: 'admin' })) &&
    location.search.includes('tab=monitoring');
  const isProphecyDownPage = location.pathname.includes(Public_Routes.ProphecyDown.getUrl());
  const { serviceNames: criticalServiceNames, resourceNames: criticalResourceNames } =
    getServiceNames(criticalServices);
  const { serviceNames: warningServiceNames, resourceNames: warningResourceNames } = getServiceNames(warningServices);
  const resourceNames = uniq(criticalResourceNames.length > 0 ? criticalResourceNames : warningResourceNames)
    .sort()
    .join(', ');
  // get unique suspending service names
  const suspendingServices = uniqBy(
    criticalServices.filter((service) => Boolean(service.suspensionCountDownInSeconds)),
    'serviceName'
  ).map((service) => ({
    serviceName: service.serviceName,
    suspensionCountDown: getDuration(service.suspensionCountDownInSeconds as number, false)
  }));

  const hasAlerts = criticalServices.length > 0 || warningServices.length > 0;

  const _1hour = 60 * 60 * 1000;
  const [snoozeNotice, toggleSnooze] = useTimedFlag(_1hour);

  useEffect(() => {
    if ((criticalServices.length > 0 || warningServices.length > 0) && !snoozeNotice) {
      toggleNotice(true);
    }
  }, [criticalServices, warningServices, snoozeNotice]);

  const getMessage = (serviceNames: string) => {
    const serviceSuspensionMessage =
      suspendingServices.length > 0
        ? `, ${suspendingServices.map((service) => `<strong>${service.serviceName}</strong> will be suspended in <strong>${service.suspensionCountDown}</strong>`).join(' and ')} to prevent data corruption`
        : '';
    return `<strong>${resourceNames}</strong> utilization of ${pluralize(serviceNames.length, 'service')} <strong>${serviceNames}</strong> ${criticalServiceNames.length > 0 ? 'have reached' : 'will reach'} critical limits and requires your immediate attention${serviceSuspensionMessage}${isAdminUser ? '.' : ', please reach out to the cluster admin.'}`;
  };

  let description: React.ReactNode = (
    <div
      dangerouslySetInnerHTML={{
        __html: hasAlerts ? getMessage(criticalServiceNames) : getMessage(warningServiceNames)
      }}
    />
  );

  const showAlerts = isLoggedIn && !isMonitoringPage && !isProphecyDownPage && showNotice && hasAlerts && !snoozeNotice;
  // for admin we show all the notifications, for non-admin we show only when there are suspending services
  const hasSuspendingServices = suspendingServices.length > 0;
  const showAlertBanner = isAdminUser ? showAlerts : hasSuspendingServices && showAlerts;

  return showAlertBanner ? (
    <StyledAlert
      banner
      key='monitoringNotification'
      closable={true}
      variant={criticalServices.length > 0 ? 'error' : 'warning'}
      onClose={() => toggleSnooze()}>
      <Stack direction='horizontal' gap={theme.spaces.x4}>
        {description}
        {isAdminUser && (
          <Link to={Private_Routes.Settings.tab.getUrl({ tab: 'admin' }, { tab: 'monitoring' })}>More Details</Link>
        )}
      </Stack>
    </StyledAlert>
  ) : null;
}
